import LeadSourceBase from './base/leadSourceBase';

//********************************************************************
// LeadSource class
// @FTGEN_PROGRESS: busy
export default class LeadSource extends LeadSourceBase
{
    //====================================================================
    // static data members

    // #FTGEN_CONSTS_A#
    static LS_DR_GOOGLE_ADS = 1;
    static LS_DR_TOP_10 = 2;
    static LS_DR_PAID_SOCIAL = 3;
    static LS_DR_SOCIAL = 4;
    static LS_DR_AFFILIATE = 5;
    static LS_DR_FRIEND__FAMILY_REFERRAL = 6;
    static LS_DR_ORGANIC_BLOG = 7;
    static LS_DR_ORGANIC_CALCULATOR = 8;
    static LS_DR_ORGANIC_AI = 9;
    static LS_DR_ORGANIC_SEARCH = 10;
    static LS_DR_ORGANIC_DIRECT = 11;
    static LS_DR_ORGANIC_EMAIL = 12;
    static LS_DR_ORGANIC_PHONE_IN = 13;
    static LS_DR_ORGANIC_WHATSAPP = 14;
    static LS_DR_ORGANIC_LIVECHAT = 15;
    static LS_SH_GOOGLE_ADS = 16;
    static LS_SH_TOP_10 = 17;
    static LS_SH_PAID_SOCIAL = 18;
    static LS_SH_SOCIAL = 19;
    static LS_SH_AFFILIATE = 20;
    static LS_SH_FRIEND__FAMILY_REFERRAL = 21;
    static LS_SH_ORGANIC_BLOG = 22;
    static LS_SH_ORGANIC_AI = 23;
    static LS_SH_ORGANIC_SEARCH = 24;
    static LS_SH_ORGANIC_DIRECT = 25;
    static LS_SH_ORGANIC_EMAIL = 26;
    static LS_SH_ORGANIC_PHONE_IN = 27;
    static LS_SH_ORGANIC_WHATSAPP = 28;
    static LS_UNKNOWN = 29;
    static LS_DR_ORGANIC_REFERRAL = 30;
    static LS_SH_ORGANIC_REFERRAL = 31;
    static LS_DR_ORGANIC_WALK_IN = 32;
    static LS_SH_ORGANIC_WALK_IN = 33;
    // #FTGEN_CONSTS_Z#
    //====================================================================
    // init
    
    constructor()
    {
        super();
    }
}
