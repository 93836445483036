import Decimal from 'decimal.js';
import moment from 'moment';
import Ft from '../../first/ft';
import { FtModel } from '../../first/ftTypes';
import FtRapi from '../../first/ftRapi';

//********************************************************************
// DcFeesOldBase class : Generated : DO NOT MODIFY
export default class DcFeesOldBase implements FtModel 
{
    //====================================================================
    // properties
    id: number;
    fees_month: Date|null;
    fees_amount: Decimal|null;

    //====================================================================
    // init

    constructor()
    {
        this.id = 0;
        this.fees_month = moment( '0001-01-01' ).toDate();
        this.fees_amount = new Decimal('0.0');
    }

    //====================================================================
    // instance api

    /// decode relevant members of obj to this members
    decodeFromObj( obj: any ): any
    {
        this.id = obj.id;
        this.fees_month = FtRapi.decodeDate( obj.fees_month );
        this.fees_amount = FtRapi.decodeDec( obj.fees_amount );
        return this;
    }

    /// encode relevant members of this to obj
    encodeToObj(): any
    {
        const map: any = {
            id : this.id,
            fees_month : FtRapi.encodeDate( this.fees_month ),
            fees_amount : FtRapi.encodeDec( this.fees_amount ),
        };
        return map;
    }

    /// copy members from src object of same type
    copyFrom( src: any ): any
    {
        this.id = Ft.parseInt0( src.id );
        this.fees_month = src.fees_month;
        this.fees_amount = src.fees_amount != null ? new Decimal( src.fees_amount ) : null;
        return this;
    }

    /// clear all members to default values
    clear(): void
    {
        this.id = 0;
        this.fees_month = moment( '0001-01-01' ).toDate();
        this.fees_amount = null;
    }
}
