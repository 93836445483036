import React, { useState, useEffect } from 'react';
import type { FtPageProps, FtDgAction } from '../first/ftTypes';
import Ft from '../first/ft';
import FtRapi from '../first/ftRapi';
import FtFrame from '../first/ftFrame';
import FtCanvas from '../first/ftCanvas';
import FtCon from '../first/ftCon';
import FtSpacer from '../first/ftSpacer';
import FtForm from '../first/ftForm';
import FtText from '../first/ftText';
import FtFmSubmit from '../first/ftFmSubmit';
import FtFmTextField from '../first/ftFmTextField';
import FtFmTextareaField from '../first/ftFmTextareaField';
import FtFmPasswordField from '../first/ftFmPasswordField';
import FtFmIntegerField from '../first/ftFmIntegerField';
import FtFmDecimalField from '../first/ftFmDecimalField';
import FtFmDateField from '../first/ftFmDateField';
import FtFmTimeField from '../first/ftFmTimeField';
import FtFmCheckboxField from '../first/ftFmCheckboxField';
import FtFmDropdownField from '../first/ftFmDropdownField';
import FtFmSearchField from '../first/ftFmSearchField';
import FtButton from '../first/ftButton';
import FtDataGrid from '../first/ftDataGrid';
import { ftBusy } from '../first/ftBusy';
import { ftPopupMessage } from '../first/ftPopupMessage';
import { ftPopupConfirm } from '../first/ftPopupOptions';
import FtSs from '../first/ftSs';
import Th from '../general/th';
import Ut from '../general/ut';

import Campaign                           from '../models/campaign';
import GenLookup                          from '../models/genLookup';

//********************************************************************
// PageCampaignEdit
// @FTGEN_PROGRESS: busy
// ...todo: specify props here...
export default function PageCampaignEdit( { navigation, options }: FtPageProps ): JSX.Element
{
    //====================================================================
    // hooks: no conditions here
    
    //- hooks: state -----
    const nav = Ft.useNav( navigation );
    const { campaignId } = nav.params ?? { campaignId: 0 };
    const [ popup                  , setPopup                  ] = useState( <></> ); // placeholder for any popup message/options
    const [ busy                   , setBusy                   ] = useState( false );
    const [ mdlCampaign            , setMdlCampaign            ] = useState<Campaign|null>( null );
    const [ lkpLeadSource          , setLkpLeadSource          ] = useState<GenLookup[]>( [] );

    //- hooks: effect/startup -----
    useEffect( onLoad, [] );


    //====================================================================
    // render
    
    if ( busy || Ft.eAny( lkpLeadSource, mdlCampaign ) ) return ftBusy( { popup: popup } );

    return (<FtCanvas {...Th.page}>
        {popup}

        <FtFrame text='Campaign' {...Th.frame}>
            <FtForm {...Th.form}
                models={{ m: mdlCampaign }}
                onSubmit={onSave}
            >
                <FtFmTextField     name='m.name'                label='Name'         ftreq  {...Th.field} />
                <FtFmTextField     name='m.campaign_idno'       label='Campaign ID'  ftreq  {...Th.field} />
                <FtFmDropdownField name='m.source_id'           label='Lead Source'  ftreq  {...Th.field} data={lkpLeadSource} />
                <FtSpacer />
                <FtFmSubmit ss='3:6:3|9:3' label='Ssave' {...Th.button} />
                <FtSpacer />
            </FtForm>
        </FtFrame>

    </FtCanvas>);

    //====================================================================
    // event handlers

    function onLoad(): void 
    {
        FtRapi.callList( 'leadSourceLookup', {
            success: ( result, data ) => setLkpLeadSource( data[0] ),
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: nav.pop } ),
        });
        if ( campaignId != 0 ) {  // Existing Campaign
            FtRapi.callRead( 'campaignRead', campaignId, {
                success: ( result, data ) => setMdlCampaign( data[0] ),
                error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: nav.pop } ),
            });
        } else {                  // New Campaign
            setMdlCampaign( new Campaign() );
        }
    }

    function onSave(): void
    {
        setBusy( true );
        if ( campaignId != 0 ) {    // Existing Campaign
            FtRapi.callUpdate( 'campaignUpdate', campaignId, {
                inRec: mdlCampaign,
                success: ( result, data ) => ftPopupMessage( setPopup, "Success", result.message, { onClose: nav.pop } ),
                error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, {} ),
                complete: () => setBusy( false ),
            });
        } else {                    // New Campaign
            FtRapi.callNew( 'campaignNew', {
                inRec: mdlCampaign,
                success: ( result, data ) => ftPopupMessage( setPopup, "Success", result.message, { onClose: nav.pop } ),
                error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, {} ),
                complete: () => setBusy( false ),
            });
        }
    }

}
