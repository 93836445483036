import Decimal from 'decimal.js';
import moment from 'moment';
import Ft from '../../first/ft';
import { FtModel } from '../../first/ftTypes';
import FtRapi from '../../first/ftRapi';

//********************************************************************
// LeadBase class : Generated : DO NOT MODIFY
export default class LeadBase implements FtModel 
{
    //====================================================================
    // properties
    id: number;
    create_dttm: Date|null;
    email: string|null;
    phone: string|null;
    code: string|null;
    is_joint: boolean|null;
    repayment_amount: Decimal|null;
    revenue_amount: Decimal|null;
    form_16_date: Date|null;
    is_signed: boolean|null;
    source_id: number|null;
    comment_first: string|null;
    campaign_id: number|null;

    //====================================================================
    // init

    constructor()
    {
        this.id = 0;
        this.create_dttm = moment( '0001-01-01 00:00:00' ).toDate();
        this.email = '';
        this.phone = '';
        this.code = '';
        this.is_joint = false;
        this.repayment_amount = new Decimal('0.0');
        this.revenue_amount = new Decimal('0.0');
        this.form_16_date = moment( '0001-01-01' ).toDate();
        this.is_signed = false;
        this.source_id = 0;
        this.comment_first = '';
        this.campaign_id = 0;
    }

    //====================================================================
    // instance api

    /// decode relevant members of obj to this members
    decodeFromObj( obj: any ): any
    {
        this.id = obj.id;
        this.create_dttm = FtRapi.decodeDttm( obj.create_dttm );
        this.email = obj.email;
        this.phone = obj.phone;
        this.code = obj.code;
        this.is_joint = obj.is_joint;
        this.repayment_amount = FtRapi.decodeDec( obj.repayment_amount );
        this.revenue_amount = FtRapi.decodeDec( obj.revenue_amount );
        this.form_16_date = FtRapi.decodeDate( obj.form_16_date );
        this.is_signed = obj.is_signed;
        this.source_id = obj.source_id;
        this.comment_first = obj.comment_first;
        this.campaign_id = obj.campaign_id;
        return this;
    }

    /// encode relevant members of this to obj
    encodeToObj(): any
    {
        const map: any = {
            id : this.id,
            create_dttm : FtRapi.encodeDttm( this.create_dttm ),
            email : FtRapi.encodeStr( this.email ),
            phone : FtRapi.encodeStr( this.phone ),
            code : FtRapi.encodeStr( this.code ),
            is_joint : FtRapi.encodeBool( this.is_joint ),
            repayment_amount : FtRapi.encodeDec( this.repayment_amount ),
            revenue_amount : FtRapi.encodeDec( this.revenue_amount ),
            form_16_date : FtRapi.encodeDate( this.form_16_date ),
            is_signed : FtRapi.encodeBool( this.is_signed ),
            source_id : this.source_id,
            comment_first : FtRapi.encodeStr( this.comment_first ),
            campaign_id : this.campaign_id,
        };
        return map;
    }

    /// copy members from src object of same type
    copyFrom( src: any ): any
    {
        this.id = Ft.parseInt0( src.id );
        this.create_dttm = src.create_dttm;
        this.email = src.email;
        this.phone = src.phone;
        this.code = src.code;
        this.is_joint = Ft.parseBool( src.is_joint );
        this.repayment_amount = src.repayment_amount != null ? new Decimal( src.repayment_amount ) : null;
        this.revenue_amount = src.revenue_amount != null ? new Decimal( src.revenue_amount ) : null;
        this.form_16_date = src.form_16_date;
        this.is_signed = Ft.parseBool( src.is_signed );
        this.source_id = Ft.parseInt0Null( src.source_id );
        this.comment_first = src.comment_first;
        this.campaign_id = Ft.parseInt0Null( src.campaign_id );
        return this;
    }

    /// clear all members to default values
    clear(): void
    {
        this.id = 0;
        this.create_dttm = moment( '0001-01-01 00:00:00' ).toDate();
        this.email = null;
        this.phone = null;
        this.code = null;
        this.is_joint = false;
        this.repayment_amount = null;
        this.revenue_amount = null;
        this.form_16_date = moment( '0001-01-01' ).toDate();
        this.is_signed = false;
        this.source_id = null;
        this.comment_first = null;
        this.campaign_id = null;
    }
}
