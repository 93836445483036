import React, { useState, useEffect } from 'react';
import type { FtPageProps, FtDgAction } from '../first/ftTypes';
import Ft from '../first/ft';
import FtRapi from '../first/ftRapi';
import FtFrame from '../first/ftFrame';
import FtCanvas from '../first/ftCanvas';
import FtCon from '../first/ftCon';
import FtSpacer from '../first/ftSpacer';
import FtForm from '../first/ftForm';
import FtText from '../first/ftText';
import FtFmSubmit from '../first/ftFmSubmit';
import FtFmTextField from '../first/ftFmTextField';
import FtFmTextareaField from '../first/ftFmTextareaField';
import FtFmPasswordField from '../first/ftFmPasswordField';
import FtFmIntegerField from '../first/ftFmIntegerField';
import FtFmDecimalField from '../first/ftFmDecimalField';
import FtFmDateField from '../first/ftFmDateField';
import FtFmTimeField from '../first/ftFmTimeField';
import FtFmCheckboxField from '../first/ftFmCheckboxField';
import FtFmDropdownField from '../first/ftFmDropdownField';
import FtFmSearchField from '../first/ftFmSearchField';
import FtButton from '../first/ftButton';
import FtDataGrid from '../first/ftDataGrid';
import { ftBusy } from '../first/ftBusy';
import { ftPopupMessage } from '../first/ftPopupMessage';
import { ftPopupConfirm } from '../first/ftPopupOptions';
import FtSs from '../first/ftSs';
import Th from '../general/th';
import Ut from '../general/ut';

import GenLookup                          from '../models/genLookup';
import Lead                               from '../models/lead';

//********************************************************************
// PageLeadUpdate
// @FTGEN_PROGRESS: done
// ...todo: specify props here...
export default function PageLeadUpdate( { navigation, options }: FtPageProps ): JSX.Element
{
    //====================================================================
    // hooks: no conditions here
    
    //- hooks: state -----
    const nav = Ft.useNav( navigation );
    const { leadId } = nav.params ?? { leadId: 0 };
    const [ popup                  , setPopup                  ] = useState( <></> ); // placeholder for any popup message/options
    const [ busy                   , setBusy                   ] = useState( false );
    const [ mdlLead                , setMdlLead                ] = useState<Lead|null>( null );
    const [ lkpSource              , setLkpSource              ] = useState<GenLookup[]>( [] );
    const [ lkpCampaign            , setLkpCampaign            ] = useState<GenLookup[]>( [] );
    const [ formKey                , setFormKey                ] = useState<string>( "abc" );

    //- hooks: effect/startup -----
    useEffect( onLoad, [] );


    //====================================================================
    // render
    
    if ( busy || Ft.eAny( lkpSource, lkpCampaign, mdlLead ) ) return ftBusy( { popup: popup } );

    const dateFld: any = {
        ext         : '0|1|1|1|1',
        ss          : '12|0:5:0|0:2:0|0:2:0|0:2:0',
        labSs       : '0|4|2|2|2',
        bkgClr      : '#ffffff',
        pad         : '3,5,3,5',
        labMd       : 'r',
        labLt       : 'z,c',
        labTxtDescr : '16,#000000',
        labPad      : '16,5,0,5',
    };
    const btnFld: any = {
        ss          : '12|4|2|1|1',
    };

    return (<FtCanvas {...Th.page}>
        {popup}

        <FtCon ss='12|12|12|9' md='rw' lt='z,a' marB={15}>
        </FtCon>
                
        <FtFrame text='Lead Details' {...Th.frame}>
            <FtForm key={formKey}
                {...Th.form}
                models={{ m: mdlLead }}
                onSubmit={onLeadUpdate}
            >
                <FtFmDateField     name='m.create_dttm'          label='Created Date'       {...Th.field} en={0} />
                <FtFmDateField     name='m.form_16_date'         label='Form 16 Date'       {...dateFld} />
                <FtButton          label='Today'                 onTap={onSetDate}          {...btnFld} />

                <FtFmTimeField     name='m.create_dttm'          label='Created Time'       {...Th.field} en={0} />
                <FtFmDecimalField  name='m.repayment_amount'     label='Repayment Amount'   {...Th.field} />

                <FtFmTextField     name='m.email'                label='Email'              {...Th.field} en={0} />
                <FtFmDropdownField name='m.source_id'            label='Source'             {...Th.field} data={lkpSource} />

                <FtFmTextField     name='m.phone'                label='Phone'              {...Th.field} en={0} />
                <FtFmDropdownField name='m.campaign_id'          label='Campaign'           {...Th.field} data={lkpCampaign} />
                
                <FtFmCheckboxField name='m.is_joint'             label='Is Joint'           {...Th.field}  />
                <FtSpacer />

                <FtFmTextareaField name='m.comment_first'        label='First Comment'      {...Th.fieldLg12} lines={20} en={0} />
                <FtSpacer />

                <FtFmSubmit ss='3:6:3|9:3' label='Save' {...Th.button} />
                <FtSpacer />
            </FtForm>
        </FtFrame>

    </FtCanvas>);

    //====================================================================
    // event handlers

    function onLoad(): void 
    {
        FtRapi.callList( 'leadSourceLookup', {
            success: ( result, data ) => {
                setLkpSource( data[0] );
                setLkpCampaign( data[1] );
            },
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: nav.pop } ),
        });
        FtRapi.callRead( 'leadRead', leadId, {
            success: ( result, data ) => setMdlLead( data[0] ),
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: nav.pop } ),
        });
    }

    function onLeadUpdate(): void
    {
        setBusy( true );
        FtRapi.callUpdate( 'leadUpdate', leadId, {
            inRec: mdlLead,
            success: ( { message }, data ) => ftPopupMessage( setPopup, "Success", message, { btn: "Close", onClose: nav.pop } ),
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message ),
            complete: () => setBusy( false ),
        });
    }

    function onSetDate(): void
    {
        mdlLead!.form_16_date = Ft.dtmAsDate( Ft.dtToday() );
        setFormKey( Ft.randomStr() );
    }

}
