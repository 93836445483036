import React, { useState, useEffect } from 'react';
import type { FtPageProps, FtDgAction } from '../first/ftTypes';
import Ft from '../first/ft';
import FtRapi from '../first/ftRapi';
import FtFrame from '../first/ftFrame';
import FtCanvas from '../first/ftCanvas';
import FtCon from '../first/ftCon';
import FtSpacer from '../first/ftSpacer';
import FtButton from '../first/ftButton';
import FtForm from '../first/ftForm';
import FtFmSubmit from '../first/ftFmSubmit';
import FtFmTextField from '../first/ftFmTextField';
import FtFmDropdownField from '../first/ftFmDropdownField';
import FtDataGrid from '../first/ftDataGrid';
import { ftBusy } from '../first/ftBusy';
import { ftPopupMessage } from '../first/ftPopupMessage';
import { ftPopupConfirm } from '../first/ftPopupOptions';
import Th from '../general/th';

import Campaign    from '../models/campaign';
import GenLookup   from '../models/genLookup';

//********************************************************************
// PageCampaignSearch
// @FTGEN_PROGRESS: done
// ...todo: specify props here...
export default function PageCampaignSearch( { navigation, options }: FtPageProps ): JSX.Element
{
    //====================================================================
    // hooks: no conditions here
    
    //- hooks: state -----
    const nav = Ft.useNav( navigation );
    //const { parameter } = nav.params ?? { parameter };    // unpack parameters passed from calling page
    const [ popup                  , setPopup                  ] = useState( <></> ); // placeholder for any popup message/options
    const [ busy                   , setBusy                   ] = useState( false );
    const [ mdlCampaign            , setMdlCampaign            ] = useState<Campaign|null>( new Campaign() );
    const [ lkpLeadSource          , setLkpLeadSource          ] = useState<GenLookup[]>( [] );
    const [ lstCampaign            , setLstCampaign            ] = useState<Campaign[]>( [] );

    //- hooks: effect/startup -----
    useEffect( onLoad, [] );


    //====================================================================
    // render
    
    if ( busy || Ft.eAny( lkpLeadSource ) ) return ftBusy( { popup: popup } );

    return (<FtCanvas {...Th.page}>
        {popup}

        <FtCon ss='12|12|12|9' md='rw' lt='z,a' marB={15}>
            <FtButton label="New Campaign" onTap={onTapCampaignNew} {...Th.button} />
        </FtCon>
                
        <FtFrame text='Search Parameters' {...Th.frame}>
            <FtForm {...Th.form}
                models={{ m: mdlCampaign }}
                onSubmit={onSearch}
            >
                <FtFmTextField     name='m.name'                 label='Name'          {...Th.field} />
                <FtFmTextField     name='m.campaign_idno'        label='Campaign ID'   {...Th.field} />
                <FtFmDropdownField name='m.source_id'            label='Lead Source'   {...Th.field} data={lkpLeadSource} />
                <FtSpacer />
                <FtFmSubmit ss='3:6:3|9:3' label='Search' {...Th.button} />
                <FtSpacer />
            </FtForm>
        </FtFrame>
        

        <FtFrame text='Search Results' {...Th.frame} ss='12'>
            <FtDataGrid {...Th.grid} pgsize={50}
                data={lstCampaign}
                cols={[
                    { fld: ''                    , hd: 'Edit'         , type: 'act'  , flex: 1, fmt: 'mc-pencil', clr: '#808080', act: onTapCampaignEdit },
                    { fld: 'name'                , hd: 'Name'         , type: 'txt'  , flex: 3 },
                    { fld: 'campaign_idno'       , hd: 'Campaign ID'  , type: 'txt'  , flex: 3 },
                    { fld: 'source_id'           , hd: 'Source'       , type: 'lkp'  , flex: 3, lkp: lkpLeadSource },
                    { fld: ''                    , hd: 'Delete'       , type: 'act'  , flex: 1, fmt: 'fa-trash', clr: '#808080', act: onTapCampaignDelete },
                ]}
            />
        </FtFrame>
        

    </FtCanvas>);

    //====================================================================
    // event handlers

    function onLoad(): void 
    {
        FtRapi.callList( 'leadSourceLookup', {
            success: ( result, data ) => setLkpLeadSource( data[0] ),
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: nav.pop } ),
        });
    }

    function onSearch(): void
    {
        setBusy( true );
        FtRapi.callList( 'campaignSearch', {
            urlParms: {
                prm_name          : mdlCampaign?.name,
                prm_campaign_idno : mdlCampaign?.campaign_idno,
                prm_source_id     : mdlCampaign?.source_id
            },
            success: ( result, data ) => setLstCampaign( data[0] ),
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message ),
            complete: () => setBusy( false ),
        });
    }

    function onTapCampaignNew(): void
    {
        nav.push( 'CampaignEdit', { campaignId: 0 } );
    }

    function onTapCampaignEdit( { dataId }: FtDgAction ): void
    {
        nav.push( 'CampaignEdit', { campaignId: dataId } );
    }

    function onTapCampaignDelete( { dataId }: FtDgAction ): void
    {
        ftPopupConfirm( setPopup, 'Confirmation', "Are you sure you want to delete this record?", {
            btnA: 'Yes', onA: () => {
                setLstCampaign( [] );
                setBusy( true );
                FtRapi.callDelete( 'campaignDelete', dataId, {
                    success: ( { message }, data ) => ftPopupMessage( setPopup, "Success", message, { onClose: onSearch } ),
                    error: ( { message } ) => ftPopupMessage( setPopup, "Error", message ),
                    complete: () => setBusy( false ),
                });
            },
            btnB: 'No', onB: () => {}
        });
    }
}
