import LeadFreshBase from './base/leadFreshBase';

//********************************************************************
// LeadFresh class
// @FTGEN_PROGRESS: busy
export default class LeadFresh extends LeadFreshBase
{
    //====================================================================
    // static data members

    // #FTGEN_CONSTS_A#
    // #FTGEN_CONSTS_Z#

    //====================================================================
    // init
    
    constructor()
    {
        super();
    }
}
