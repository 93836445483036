import DcFeesOldBase from './base/dcFeesOldBase';

//********************************************************************
// DcFeesOld class
// @FTGEN_PROGRESS: busy
export default class DcFeesOld extends DcFeesOldBase
{
    //====================================================================
    // static data members

    // #FTGEN_CONSTS_A#
    // #FTGEN_CONSTS_Z#

    //====================================================================
    // init
    
    constructor()
    {
        super();
    }
}
