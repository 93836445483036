import Decimal from 'decimal.js';
import moment from 'moment';
import Ft from '../../first/ft';
import { FtModel } from '../../first/ftTypes';
import FtRapi from '../../first/ftRapi';

//********************************************************************
// CampaignBase class : Generated : DO NOT MODIFY
export default class CampaignBase implements FtModel 
{
    //====================================================================
    // properties
    id: number;
    name: string|null;
    campaign_idno: string|null;
    source_id: number|null;

    //====================================================================
    // init

    constructor()
    {
        this.id = 0;
        this.name = '';
        this.campaign_idno = '';
        this.source_id = 0;
    }

    //====================================================================
    // instance api

    /// decode relevant members of obj to this members
    decodeFromObj( obj: any ): any
    {
        this.id = obj.id;
        this.name = obj.name;
        this.campaign_idno = obj.campaign_idno;
        this.source_id = obj.source_id;
        return this;
    }

    /// encode relevant members of this to obj
    encodeToObj(): any
    {
        const map: any = {
            id : this.id,
            name : FtRapi.encodeStr( this.name ),
            campaign_idno : FtRapi.encodeStr( this.campaign_idno ),
            source_id : this.source_id,
        };
        return map;
    }

    /// copy members from src object of same type
    copyFrom( src: any ): any
    {
        this.id = Ft.parseInt0( src.id );
        this.name = src.name;
        this.campaign_idno = src.campaign_idno;
        this.source_id = Ft.parseInt0Null( src.source_id );
        return this;
    }

    /// clear all members to default values
    clear(): void
    {
        this.id = 0;
        this.name = null;
        this.campaign_idno = null;
        this.source_id = null;
    }
}
