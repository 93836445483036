import LeadSourceFreshBase from './base/leadSourceFreshBase';

//********************************************************************
// LeadSourceFresh class
// @FTGEN_PROGRESS: busy
export default class LeadSourceFresh extends LeadSourceFreshBase
{
    //====================================================================
    // static data members

    // #FTGEN_CONSTS_A#
    static LSF_DR_GOOGLE_ADS = 1;
    static LSF_DR_TOP_10 = 2;
    static LSF_DR_FACEBOOK = 3;
    static LSF_DR_INSTAGRAM = 4;
    static LSF_DR_TIKTOK = 5;
    static LSF_DR_LINKEDIN = 6;
    static LSF_DR_AFFILIATE = 7;
    static LSF_DR_DEBT_RESCUE_AI = 8;
    static LSF_DR_ORGANIC_SEARCH = 9;
    static LSF_DR_ORGANIC_DIRECT = 10;
    static LSF_DR_ORGANIC_REFERRAL = 11;
    static LSF_DR_MANUAL = 12;
    static LSF_SH_GOOGLE_ADS = 13;
    static LSF_SH_TOP_10 = 14;
    static LSF_SH_FACEBOOK = 15;
    static LSF_SH_INSTAGRAM = 16;
    static LSF_SH_TIKTOK = 17;
    static LSF_SH_LINKEDIN = 18;
    static LSF_SH_AFFILIATE = 19;
    static LSF_SH_DEBT_RESCUE_AI = 20;
    static LSF_SH_ORGANIC_SEARCH = 21;
    static LSF_SH_ORGANIC_DIRECT = 22;
    static LSF_SH_ORGANIC_REFERRAL = 23;
    static LSF_SH_MANUAL = 24;
    static LSF_UNKNOWN = 25;
    // #FTGEN_CONSTS_Z#

    //====================================================================
    // init
    
    constructor()
    {
        super();
    }
}
