import CampaignBase from './base/campaignBase';

//********************************************************************
// Campaign class
// @FTGEN_PROGRESS: busy
export default class Campaign extends CampaignBase
{
    //====================================================================
    // static data members

    // #FTGEN_CONSTS_A#
    static C_CAR_REPOSSESSION = 1;
    static C_DEBT_EXPERT = 2;
    static C_DEBT_CONSOLIDATION__DEBT_CONSOLIDATION = 3;
    static C_DEBT_REVIEW__DEBT_REVIEW = 4;
    static C_APPLY_FOR_DEBT_REVIEW__EXACT = 5;
    static C_BRAND__DEBT_RESCUE__TRIAL_177 = 6;
    static C_DEBT_REVIEW__DEBT_REVIEW_EXPERIMENT__DEBT_REVIEW_RECOMMENDATIONS_TRIAL = 7;
    static C_DEBT_COUNSELLING__CATCH_ALL = 8;
    static C_DEBT_COUNSELLING__DEBT_COUNSELLORS = 9;
    static C_DEBT_COUNSELLING__DEBT_RELIEF = 10;
    static C_BRAND__DEBT_RESCUE = 11;
    static C_DEMAND_GEN__DEBT_RESCUE = 12;
    static C_DEBT_COUNSELLING__AFRIKAANS = 13;
    static C_DISPLAY_CAMPAIGN__CELLPHONE_PROMOTION = 14;
    static C_DEBT_REVIEW__DEBT_REVIEW_TRIAL_868 = 15;
    static C_DEBT_COUNSELLING__DEBT_COUNSELLING_TRIAL_192 = 16;
    static C_DEMAND_GEN__DEBT_RESCUE_NEW__AUG_2024 = 17;
    static C_BRAND__DEBT_RESCUE_EXPERIMENT__BRAND__DEBT_RESCUE_RECOMMENDATIONS_TRIAL = 18;
    static C_DSA__ACCOUNT_LEVEL = 19;
    static C_DEBT_COUNSELLING__DEBT_HELP = 20;
    static C_DISPLAY__DEBT_REVIEW_PROTECTING_ASSETS = 21;
    static C_DEBT_CONSOLIDATION__DEBT_CONSOLIDATION_TRIAL_135 = 22;
    static C_PERFORMANCE_MAX__AUG_2023 = 23;
    static C_DEBT_COUNSELLING__DEBT_COUNSELLING = 24;
    static C_BRAND__DEBT_RESCUE_TRIAL_105 = 25;
    // #FTGEN_CONSTS_Z#
    //====================================================================
    // init
    
    constructor()
    {
        super();
    }
}
