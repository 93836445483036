import Decimal from 'decimal.js';
import moment from 'moment';
import Ft from '../../first/ft';
import { FtModel } from '../../first/ftTypes';
import FtRapi from '../../first/ftRapi';

//********************************************************************
// LeadFreshBase class : Generated : DO NOT MODIFY
export default class LeadFreshBase implements FtModel 
{
    //====================================================================
    // properties
    id: number;
    freshid: number|null;
    is_joint: boolean|null;
    repayment_amount: Decimal|null;
    revenue_amount: Decimal|null;
    is_signed: boolean|null;
    source_id: number|null;
    campaign_id: number|null;
    fs_created_at: Date|null;
    fs_updated_at: Date|null;
    fs_email: string|null;
    fs_won_deals_amount: Decimal|null;
    fs_cf_joint__single: string|null;
    fs_cf_form_16_date_signed: Date|null;
    fs_work_number: string|null;
    fs_mobile_number: string|null;
    fs_cf_lead_source: string|null;
    fs_cf_website: string|null;
    fs_first_name: string|null;
    fs_last_name: string|null;
    fs_display_name: string|null;
    fs_cf_closed_ife: string|null;
    fs_cf_signed_date: Date|null;

    //====================================================================
    // init

    constructor()
    {
        this.id = 0;
        this.freshid = 0;
        this.is_joint = false;
        this.repayment_amount = new Decimal('0.0');
        this.revenue_amount = new Decimal('0.0');
        this.is_signed = false;
        this.source_id = 0;
        this.campaign_id = 0;
        this.fs_created_at = moment( '0001-01-01 00:00:00' ).toDate();
        this.fs_updated_at = moment( '0001-01-01 00:00:00' ).toDate();
        this.fs_email = '';
        this.fs_won_deals_amount = new Decimal('0.0');
        this.fs_cf_joint__single = '';
        this.fs_cf_form_16_date_signed = moment( '0001-01-01 00:00:00' ).toDate();
        this.fs_work_number = '';
        this.fs_mobile_number = '';
        this.fs_cf_lead_source = '';
        this.fs_cf_website = '';
        this.fs_first_name = '';
        this.fs_last_name = '';
        this.fs_display_name = '';
        this.fs_cf_closed_ife = '';
        this.fs_cf_signed_date = moment( '0001-01-01 00:00:00' ).toDate();
    }

    //====================================================================
    // instance api

    /// decode relevant members of obj to this members
    decodeFromObj( obj: any ): any
    {
        this.id = obj.id;
        this.freshid = obj.freshid;
        this.is_joint = obj.is_joint;
        this.repayment_amount = FtRapi.decodeDec( obj.repayment_amount );
        this.revenue_amount = FtRapi.decodeDec( obj.revenue_amount );
        this.is_signed = obj.is_signed;
        this.source_id = obj.source_id;
        this.campaign_id = obj.campaign_id;
        this.fs_created_at = FtRapi.decodeDttm( obj.fs_created_at );
        this.fs_updated_at = FtRapi.decodeDttm( obj.fs_updated_at );
        this.fs_email = obj.fs_email;
        this.fs_won_deals_amount = FtRapi.decodeDec( obj.fs_won_deals_amount );
        this.fs_cf_joint__single = obj.fs_cf_joint__single;
        this.fs_cf_form_16_date_signed = FtRapi.decodeDttm( obj.fs_cf_form_16_date_signed );
        this.fs_work_number = obj.fs_work_number;
        this.fs_mobile_number = obj.fs_mobile_number;
        this.fs_cf_lead_source = obj.fs_cf_lead_source;
        this.fs_cf_website = obj.fs_cf_website;
        this.fs_first_name = obj.fs_first_name;
        this.fs_last_name = obj.fs_last_name;
        this.fs_display_name = obj.fs_display_name;
        this.fs_cf_closed_ife = obj.fs_cf_closed_ife;
        this.fs_cf_signed_date = FtRapi.decodeDttm( obj.fs_cf_signed_date );
        return this;
    }

    /// encode relevant members of this to obj
    encodeToObj(): any
    {
        const map: any = {
            id : this.id,
            freshid : this.freshid,
            is_joint : FtRapi.encodeBool( this.is_joint ),
            repayment_amount : FtRapi.encodeDec( this.repayment_amount ),
            revenue_amount : FtRapi.encodeDec( this.revenue_amount ),
            is_signed : FtRapi.encodeBool( this.is_signed ),
            source_id : this.source_id,
            campaign_id : this.campaign_id,
            fs_created_at : FtRapi.encodeDttm( this.fs_created_at ),
            fs_updated_at : FtRapi.encodeDttm( this.fs_updated_at ),
            fs_email : FtRapi.encodeStr( this.fs_email ),
            fs_won_deals_amount : FtRapi.encodeDec( this.fs_won_deals_amount ),
            fs_cf_joint__single : FtRapi.encodeStr( this.fs_cf_joint__single ),
            fs_cf_form_16_date_signed : FtRapi.encodeDttm( this.fs_cf_form_16_date_signed ),
            fs_work_number : FtRapi.encodeStr( this.fs_work_number ),
            fs_mobile_number : FtRapi.encodeStr( this.fs_mobile_number ),
            fs_cf_lead_source : FtRapi.encodeStr( this.fs_cf_lead_source ),
            fs_cf_website : FtRapi.encodeStr( this.fs_cf_website ),
            fs_first_name : FtRapi.encodeStr( this.fs_first_name ),
            fs_last_name : FtRapi.encodeStr( this.fs_last_name ),
            fs_display_name : FtRapi.encodeStr( this.fs_display_name ),
            fs_cf_closed_ife : FtRapi.encodeStr( this.fs_cf_closed_ife ),
            fs_cf_signed_date : FtRapi.encodeDttm( this.fs_cf_signed_date ),
        };
        return map;
    }

    /// copy members from src object of same type
    copyFrom( src: any ): any
    {
        this.id = Ft.parseInt0( src.id );
        this.freshid = Ft.parseInt0Null( src.freshid );
        this.is_joint = Ft.parseBool( src.is_joint );
        this.repayment_amount = src.repayment_amount != null ? new Decimal( src.repayment_amount ) : null;
        this.revenue_amount = src.revenue_amount != null ? new Decimal( src.revenue_amount ) : null;
        this.is_signed = Ft.parseBool( src.is_signed );
        this.source_id = Ft.parseInt0Null( src.source_id );
        this.campaign_id = Ft.parseInt0Null( src.campaign_id );
        this.fs_created_at = src.fs_created_at;
        this.fs_updated_at = src.fs_updated_at;
        this.fs_email = src.fs_email;
        this.fs_won_deals_amount = src.fs_won_deals_amount != null ? new Decimal( src.fs_won_deals_amount ) : null;
        this.fs_cf_joint__single = src.fs_cf_joint__single;
        this.fs_cf_form_16_date_signed = src.fs_cf_form_16_date_signed;
        this.fs_work_number = src.fs_work_number;
        this.fs_mobile_number = src.fs_mobile_number;
        this.fs_cf_lead_source = src.fs_cf_lead_source;
        this.fs_cf_website = src.fs_cf_website;
        this.fs_first_name = src.fs_first_name;
        this.fs_last_name = src.fs_last_name;
        this.fs_display_name = src.fs_display_name;
        this.fs_cf_closed_ife = src.fs_cf_closed_ife;
        this.fs_cf_signed_date = src.fs_cf_signed_date;
        return this;
    }

    /// clear all members to default values
    clear(): void
    {
        this.id = 0;
        this.freshid = null;
        this.is_joint = false;
        this.repayment_amount = null;
        this.revenue_amount = null;
        this.is_signed = false;
        this.source_id = null;
        this.campaign_id = null;
        this.fs_created_at = moment( '0001-01-01 00:00:00' ).toDate();
        this.fs_updated_at = moment( '0001-01-01 00:00:00' ).toDate();
        this.fs_email = null;
        this.fs_won_deals_amount = null;
        this.fs_cf_joint__single = null;
        this.fs_cf_form_16_date_signed = moment( '0001-01-01 00:00:00' ).toDate();
        this.fs_work_number = null;
        this.fs_mobile_number = null;
        this.fs_cf_lead_source = null;
        this.fs_cf_website = null;
        this.fs_first_name = null;
        this.fs_last_name = null;
        this.fs_display_name = null;
        this.fs_cf_closed_ife = null;
        this.fs_cf_signed_date = moment( '0001-01-01 00:00:00' ).toDate();
    }
}
