import React, { useState, useEffect } from 'react';
import type { FtPageProps, FtDgAction } from '../first/ftTypes';
import Ft from '../first/ft';
import FtFrame from '../first/ftFrame';
import FtCanvas from '../first/ftCanvas';
import FtCon from '../first/ftCon';
import FtSpacer from '../first/ftSpacer';
import FtButton from '../first/ftButton';
import { ftBusy } from '../first/ftBusy';
import Th from '../general/th';
import Ut from '../general/ut';

//********************************************************************
// PageMenu
// @FTGEN_PROGRESS: done
// ...todo: specify props here...
export default function PageMenu( { navigation, options }: FtPageProps ): JSX.Element
{
    //====================================================================
    // hooks: no conditions here

    //- hooks: state -----
    const nav = Ft.useNav( navigation );
    const [ popup                  , setPopup                  ] = useState( <></> ); // placeholder for any popup message/options
    const [ busy                   , setBusy                   ] = useState<boolean>( false );

    //- hooks: effect/startup -----
    useEffect( onLoad, [] );

    //====================================================================
    // render

    if ( busy ) return ftBusy( { popup: popup } );

    const stl: any = { txtClr: '#ffffff' };

    return (<FtCanvas {...Th.page}>
        {popup}

        <FtFrame text='Main Menu' {...Th.menuFrame}>
            <FtCon {...Th.menuBlock}>
                <FtButton label="Marketing Disposition Dashboard"  {...Th.menuBtn}  {...stl}  onTap={() => nav.push( 'DispositionDashboard' )} />
                <FtButton label="Leads Search (IFE)"               {...Th.menuBtn}  {...stl}  onTap={() => nav.push( 'LeadSearch' )} />
                <FtButton label="Campaign Search"                  {...Th.menuBtn}  {...stl}  onTap={() => nav.push( 'CampaignSearch' )} />
            </FtCon>
        </FtFrame>

    </FtCanvas>);

    //====================================================================
    // event handlers

    function onLoad(): void
    {
        setBusy( true );
        Ut.loadUser( () => setBusy( false ) );
    }

}
